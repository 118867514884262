<template>
  <div>
    <v-tabs v-model="tab">
      <v-tab v-for="(t, i) in tabitemscomp" :key="i">{{ t.title }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="(tic, i) in tabitemscomp" :key="i">
        <component :is="tic.component" :cpar_header_id="cpar_header_id"></component>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import UpdateAnalysisA from './UpdateAnalysisA.vue';
import UpdateAnalysisB from './UpdateAnalysisB.vue';
import UpdateAnalysisC from './UpdateAnalysisC.vue';
import UpdateAnalysisD from './UpdateAnalysisD.vue';
import UpdateAnalysisE from './UpdateAnalysisE.vue';
export default {
  components: {
    UpdateAnalysisA,
    UpdateAnalysisB,
    UpdateAnalysisC,
    UpdateAnalysisD,
    UpdateAnalysisE,
  },
  props: ["cpar_header_id"],
  data() {
    return {
      tab: 0,
      tabitemscomp: [
        { title: 'A. METHOD', component: 'UpdateAnalysisA' },
        { title: 'B. MAN', component: 'UpdateAnalysisB' },
        { title: 'C. MACHINE', component: 'UpdateAnalysisC' },
        { title: 'D. MATERIAL', component: 'UpdateAnalysisD' },
        { title: 'E. ENVIRONMENTAL', component: 'UpdateAnalysisE' },
      ],
      loading: false,
    };
  },

  methods: {
    async getData(refresh = false) {
      this.loading = true;

      this.cpar_root_cause_a = [];
      this.cpar_root_cause_b = [];
      this.cpar_root_cause_c = [];
      this.cpar_root_cause_d = [];
      this.cpar_root_cause_e = [];

      if (refresh == true) this.AxiosStorageRemove("GET", "cparanalysis/data");

      var config = {
        params: {
          cpar_header_id: this.cpar_header_id,
        },
        cacheConfig: true,
      };
      await this.$axios
        .get("cparanalysis/data", config)
        .then((res) => {
          var r = res.data.data.results;

          r.forEach((itm) => {
            if (itm.category_code == "A") {
              this.cpar_root_cause_a.push(itm);
            }
            if (itm.category_code == "B") {
              this.cpar_root_cause_b.push(itm);
            }
            if (itm.category_code == "C") {
              this.cpar_root_cause_c.push(itm);
            }
            if (itm.category_code == "D") {
              this.cpar_root_cause_d.push(itm);
            }
            if (itm.category_code == "E") {
              this.cpar_root_cause_e.push(itm);
            }
          });

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.axiosErrorHandler(error);
        });
    },

    add(category_code, category_name) {
      this.fmAdd.loading = false;
      this.fmAdd.dialog = true;
      this.fmAdd.data.cpar_header_id = this.cpar_header_id;
      this.fmAdd.data.line_no_ = null;
      this.fmAdd.data.category_code = category_code;
      this.fmAdd.data.category_name = category_name;
      this.fmAdd.data.description = null;
      this.fmAdd.data.attachment = null;
    },

    async saveData() {
      this.fmAdd.dialog = false;
      this.loading = true;
      this.showLoadingOverlay(true);
      var formData = new FormData();
      formData.append("cpar_header_id", this.fmAdd.data.cpar_header_id);
      formData.append("line_no_", this.fmAdd.data.line_no_);
      formData.append("category_code", this.fmAdd.data.category_code);
      formData.append("category_name", this.fmAdd.data.category_name);
      formData.append("description", this.fmAdd.data.description);
      formData.append("action_date", this.fmAdd.data.action_date);
      formData.append("attachment", this.fmAdd.data.attachment);

      await this.$axios
        .post("cparanalysis/save", formData)
        .then((res) => {
          this.loading = false;
          this.showLoadingOverlay(false);

          var resData = res.data;

          if (resData.status == "success") {
            this.showAlert(resData.status, resData.message);

            this.getData(true);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
